<template>
  <div>
    <ValidationObserver
      ref="observer"
      tag="form"
      @submit.prevent="submitForm"
      novalidate
    >
      <div class="form-group row">
        <label class="col-2" for="category">ประเภทหวย:</label>
        <div class="col">
          <ValidationProvider rules="required" v-slot="v">
            <select
              name="ประเภทหวย"
              id="category"
              class="form-control"
              v-model="form.lottoCategoryId"
            >
              <option
                :value="item.id"
                v-for="(item, index) in categorys"
                :key="index"
              >
                {{ item.name }}
              </option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2" for="name">ชื่อหวย:</label>
        <div class="col">
          <ValidationProvider rules="required" v-slot="v">
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="form.name"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2" for="closeTime">เวลาออกผล:</label>
        <div class="col">
          <ValidationProvider rules="required" v-slot="v">
            <b-form-timepicker
              id="closeTime"
              locale="th"
              v-model="form.closeTime"
              placeholder="เลือกเวลา"
              now-button
              reset-button
              label-close-button="ปิด"
              label-no-time-selected="ยังไม่เลือก"
              label-reset-button="รีเซ็ต"
              label-now-button="ตอนนี้"
            ></b-form-timepicker>
            <input type="text" hidden v-model="form.closeTime" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2">วันเปิด:</label>
        <div class="col">
          <div class="form-check">
            <input
              id="monday"
              v-model="form.monday"
              class="form-check-input"
              type="checkbox"
            />
            <label for="monday">จันทร์</label>
          </div>
          <div class="form-check">
            <input
              id="tuesday"
              v-model="form.tuesday"
              class="form-check-input"
              type="checkbox"
            />
            <label for="tuesday">อังคาร</label>
          </div>
          <div class="form-check">
            <input
              id="wednesday"
              v-model="form.wednesday"
              class="form-check-input"
              type="checkbox"
            />
            <label for="wednesday">พุธ</label>
          </div>
          <div class="form-check">
            <input
              id="thurday"
              v-model="form.thursday"
              class="form-check-input"
              type="checkbox"
            />
            <label for="thurday">พฤหัสบดี</label>
          </div>
          <div class="form-check">
            <input
              id="friday"
              v-model="form.friday"
              class="form-check-input"
              type="checkbox"
            />
            <label for="friday">ศุกร์</label>
          </div>
          <div class="form-check">
            <input
              id="saturday"
              v-model="form.saturday"
              class="form-check-input"
              type="checkbox"
            />
            <label for="saturday">เสาร์</label>
          </div>
          <div class="form-check">
            <input
              id="sunday"
              v-model="form.sunday"
              class="form-check-input"
              type="checkbox"
            />
            <label for="sunday">อาทิตย์</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col">
          <button
            class="btn btn-danger mr-1"
            type="button"
            @click="$router.go(-1)"
          >
            ยกเลิก
          </button>
          <button class="btn btn-primary" type="submit">บันทึก</button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        lottoCategoryId: 1,
      },
      categorys: [],
      id: this.$route.params.id,
    };
  },
  methods: {
    async submitForm() {
      let observer = this.$refs.observer;
      let isValid = await observer.validate();
      if (!isValid) {
        this.focusError(observer);
        return;
      }

      this.swal.processing();
      try {
        this.id
          ? await this.axios({
              method: "put",
              url: "lotto/type",
              data: this.form,
            })
          : await this.axios({
              method: "post",
              url: "lotto/type",
              data: this.form,
            });

        this.swal.swalSuccess();
      } catch (e) {
        console.log(e);
        this.swal.swalError();
      }
    },
  },
  async mounted() {
    this.swal.processing();

    if (this.id) {
      try {
        let categoryPromise = this.axios({
          method: "get",
          url: "lotto/category",
        });
        let typePromise = await this.axios({
          method: "get",
          url: `lotto/${this.id}/type`,
        });

        let [categoryRes, typeRes] = await Promise.all([
          categoryPromise,
          typePromise,
        ]);

        this.form = typeRes.data;
        this.categorys = categoryRes.data;

        this.swal.close();
      } catch (e) {
        console.log(e);
        this.swal.swalError();
      }
    } else {
      try {
        let category = await this.axios({
          method: "get",
          url: "lotto/category",
        });

        this.categorys = category.data;

        this.swal.close();
      } catch (e) {
        console.log(e);
        this.swal.swalError();
      }
    }
  },
};
</script>